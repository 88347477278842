<template>
  <v-dialog
    content-class="dialog-add-company"
    overlay-color="primary"
    persistent
    :value="value"
    width="800px"
  >
    <v-card>
      <div class="form-compact form-compact-feed">
        <v-system-bar
          dark
          color="primary"
          absolute
        >
          <span>{{ $t('DIALOG.ADD_COMPANY_TITLE') }}</span>
          <v-spacer/>
          <v-icon
            small
            class="clickable"
            @click="$emit('close')"
          >
            mdi-close
          </v-icon>
        </v-system-bar>
      </div>

      <v-toolbar
        color="transparent"
        flat
        height="66px"
        class="mt-4"
      >
        <v-toolbar-title class="primary--text">
          {{ $t('DIALOG.ADD_NEW_COMPANY') }}
        </v-toolbar-title>
        <v-spacer />
        <v-tooltip
          bottom
          transition="none"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              small
              depressed
              :disabled="!valid"
              color="success"
              @click="saveCompany"
            >
              <v-icon>mdi-check-bold</v-icon>
            </v-btn>
          </template>
            {{ $t('COMMON.SAVE') }}
          </v-tooltip>
      </v-toolbar>

      <div class="toolbar-separator primary ml-4 mr-4"/>
      <Loading :value="loading" />
      <div class="dialog-add-company-content">
        <v-container
          fluid
          class="pt-4 pb-10"
        >
          <v-form
            ref="form"
            v-model="valid"
          >
            <FormCampaignCompactTitle
              @input="handleInput"
              :values="newCompany"
            />
          </v-form>
        </v-container>
      </div>
    </v-card>
  </v-dialog>
</template>

<style>
  .dialog-add-company {
    overflow-y: hidden;
  }

  .dialog-add-company-content {
    overflow-y: hidden;
    overflow-x: hidden;
  }
</style>

<script>
import FormCampaignCompactTitle from './../forms/campaign/compact/FormCampaignCompact.title.vue'
import Loading from '@/components/Loading.vue'

export default {
  name: 'DialogAddCompany',
  data: () => ({
    valid: false,
    loading: false,
    newCompany: {
      title: '',
      channel_categories: [],
      start_date: '',
      end_date: '',
      ga4_property_id: '',
      ga4_conversion_event_name: '',
    },
  }),
  components: {
    FormCampaignCompactTitle,
    Loading,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    newCompany() {
      if (
        this.title === "" ||
        this.channel_categories.length === 0 ||
        this.start_date === "" ||
        this.end_date === ""
      ) return
      this.valid = true
    },
  },
  methods: {
    handleInput(e) {
      const {
        title,
        channel_categories,
        start_date,
        end_date,
        ga4_property_id,
        ga4_conversion_event_name,
      } = e

      if(title) this.newCompany.title = title
      if(channel_categories) this.newCompany.channel_categories = channel_categories
      if(start_date) this.newCompany.start_date = start_date
      if(end_date) this.newCompany.end_date = end_date
      if(ga4_property_id) this.ga4_property_id = ga4_property_id
      if(ga4_conversion_event_name) this.newCompany.ga4_conversion_event_name = ga4_conversion_event_name
    },

    async saveCompany() {
      const values = this.$clone(this.newCompany)
      this.loading = true

      try {
        await this.$services.api_programmatic.campaign.create({values})
        this.$emit('load')
      } 
      catch(e) {
        await this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
      }
      finally {
        this.loading = false
        this.clearForm()
        this.$emit('close')
      }
    },

    clearForm() {
      this.newCompany.title = ""
      this.newCompany.channel_categories = []
      this.newCompany.start_date = ""
      this.newCompany.end_date = ""
      this.ga4_property_id = ''
      this.ga4_conversion_event_name = ''
    }
  }
}
</script>