<template>
  <div class="status-label" v-if="item && code">
    <v-tooltip bottom :disabled="!(next_refresh || message)">
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          :class="[vChipPadding]"
          v-on="on"
          v-bind="attrs"
          :small="small"
          :color="type"
          :x-small="xSmall"
          :large="large"
          label
        >
          <v-icon
            :size="iconSize"
            :class="[iconMargin]"
            :color="textColor"
          >
            {{ icon }}
          </v-icon>
          <span :class="textColor+'--text'">{{ code }}</span>
        </v-chip>
      </template>
      <div class="status-label-message" v-if="message">
        <span>{{ message }}</span>
      </div>
      <div v-if="next_refresh">
        {{ next_refresh }}
      </div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "StatusLabel",
  props: {
    item: {
      type: [Boolean, Object],
      default: false
    },
    textColor: {
      type: [String],
      default: 'white'
    },
    xSmall: {
      type: [Boolean],
      default: undefined
    },
    small: {
      type: [Boolean],
      default: undefined
    },
    large: {
      type: [Boolean],
      default: undefined
    },
  },
  computed: {
    type () {
      return this.item.status_type || 'grey'
    },
    iconSize () {
      if (this.xSmall) return 12
      if (this.small) return 18
      if (this.large) return 32
      return 20
    },
    iconMargin () {
      if (this.xSmall) return 'mr-2'
      if (this.small) return 'mr-2'
      if (this.large) return 'mr-3'
      return 'mr-2'
    },
    vChipPadding () {
      if (this.xSmall) return 'pl-1'
      if (this.small) return 'pl-2'
      if (this.large) return 'pl-4'
      return 'pl-2'
    },
    icon () {
      return this.item.status_code ? this.$i18n.t(`${this.item.status_code }_ICON`) : 'mdi-circle'
    },
    message () {
      return this.item.status_message ? this.item.status_message : null
    },
    code () {
      let computed_status_code = this.item.status_code ? this.$i18n.t(this.item.status_code) : null
      if (computed_status_code && this.item.status_code_suffix) {
        computed_status_code += this.item.status_code_suffix
      }
      return computed_status_code
    },
    next_refresh () {
      return this.item.need_refresh ? this.$i18n.t('COMMON.NEXT_REFRESH', { date: new Date(this.item.need_refresh).toLocaleDateString() }) : null
    }
  }
}
</script>

<style>
.status-label {
  display:inline-block;
  cursor: pointer;
}
</style>
